import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import LoginComponent from "../components/LoginComponent/LoginComponent"
import GlobalFooter from '../components/Footer/GlobalFooter'

export default () => (
    <div>
        <div>
            <Navbar />
        </div>
        <div>
            <LoginComponent />
        </div>
        <div>
            <GlobalFooter />
        </div>
    </div>
)